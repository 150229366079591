const projects_tr = [
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'HOBİ BAHÇESİ YAPIMI İŞİ', 'AYAŞ/ANKARA' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'KIŞ BAHÇESİ UYGULAMA PROJESİ', 'MUSTAFA KEMAL MAHALLESİ / ANKARA' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'HOBİ BAHÇESİ PEYZAJ DÜZENLEMESİ İŞLERİ', 'ORTABEREKET/ANKARA' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'TİNY HOUSE İNŞAATI İŞLERİ', 'GÖYNÜK/BOLU' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'TİNY HOUSE İNŞAATI VE TADİLAT İŞLERİ', 'SAPANCA/SAKARYA' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'TİNY HOUSE İNŞATI RENOVASYON İŞLERİ', 'SAPANCA/SAKARYA' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'VİLLA PROJESİ İNŞAATI VE TASARIM İŞLERİ', 'ALTINTERAZİ SİTESİ ÇAYYOLU/ANKARA' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'VİLLA PROJESİ İNŞAATI VE İÇ MİMARLIK TASARIM İŞLERİ', 'ANGORA EVLERİ  ÇANKAYA/ANKARA' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'VİLLA PROJESİ İNŞAATI VE İÇ MİMARLIK TASARIM İŞLERİ', 'MUTLUKENT MAH. ÇANKAYA/ANKARA' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'VİLLA PROJESİ İNŞAATI VE İÇ MİMARLIK TASARIM İŞLERİ', 'BAŞBEREKET/ANKARA' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', '7 KATLI APARTMAN İNŞAATI VE İÇ DEKORASYON/TASARIM YAPIMI İŞLERİ', 'MERKEZ/SİNOP' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'APARTMAN DAİRESİ TADİLAT İŞLERİ', 'MERKEZ/SİNOP' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'APARTMAN DAİRESİ İÇ DEKORASYON İŞLERİ', 'MERKEZ/SİNOP' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'APARTMAN DAİRESİ RESTORASYON İÇ MİMARİ TASARIM İŞLERİ', 'MERKEZ/SİNOP' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'VİLLA PROJESİ İNŞAATI', 'ZEKERİYAKÖY/SARIYER/İSTANBUL' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'KONUT PROJESİ İNŞAATI', 'ASPAT AKYARLAR/BODRUM' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'VİLLA PROJESİ RENOVASYON VE TADİLAT İŞLERİ', 'ORTAKENT/BODRUM' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'KONUT PROJESİ RESTORASYON İŞLERİ', 'KONUTKENT MAH./ÇANKAYA/ANKARA' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'TURİZM OFİSİ TADİLATI PROJESİ', 'AKYARLAR/BODRUM' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'VİLLA PROJESİ İNŞAATI VE PEYZAJ İŞLERİ', 'İNCEBURUN/SİNOP' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'OFİS DEKORASYON İŞLERİ', 'YENİBATI/BATIKENT/ANKARA' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'PLASTİK CERRAHİ OFİS TASARIMI İŞLERİ', 'MAİDAN PLAZA/ANKARA' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'YAZLIK PROJESİ TADİLAT İŞLERİ', 'ANTHAVEN/BODRUM' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'APARTMAN DAİRESİ İÇ TASARIM VE MOBİLYA İŞLERİ', 'RÖNESANS NEVA KONAKLARI/ANKARA' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'RESTORASYON PROJESİ', 'RÖNESANS NEVA KONAKLARI /ANKARA' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'DİŞÇİ MUAYENEHANE TADİLAT VE ONARIM İŞİ', 'İSTANBUL / BEYKOZ' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'APARTMAN DAİRESİ TADİLAT VE ONARIM İŞİ', 'KONUTKENT 2 /  ANKARA' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'KUAFÖR SALONU TADİLAT VE BAKIM ONARIM', 'KONUTKENT 2 / ANKARA' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'AVM İNŞAATI İNCE İŞLERİ', 'OSTİM / ANKARA' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'ÇOK KATLI OTOPARK İNŞAAT İŞLERİ', 'OSTİM/ANKARA' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'OTOPARK ONARIM-TADİLAT İŞLERİ', 'MESA KORU / ANKARA' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'VİLLA PROJESİ RESTORASYON RENOVASYON', 'MESA KORU / ANKARA' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'SATIŞ OFİSİ  İÇ DEKORASYON TASARIM', 'ORTAKENT/BODRUM /MUĞLA' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'HUKUK BÜROSU OFİSİ TADİLATI İŞLERİ', 'SARIYER/İSTANBUL' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'MALİKANE İNŞAATI VE PEYZAJ İŞLERİ', 'İNCEK/ANKARA' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'APARTMAN DAİRESİ TADİLAT VE MOBİLYA TASARIMI İŞLERİ', 'KADIKÖY/İSTANBUL' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'REZİDANS PROJESİ İÇ TASARIM VE MOBİLYA İŞLERİ', 'ARNAVUTKÖY/İSTANBUL' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'GÜNERİ VİLLALARI ONARIM VE GÜÇLENDİRME İŞİ', 'MERKEZ/SİNOP' ],
    [ 'YAPIM İŞİ ÖZEL SEKTÖR', 'PREFABRİK BİNA YAPIM İŞİ', 'GÖCEK/FETHİYE' ],
    [ 'HİZMET İŞİ ÖZEL SEKTÖR', 'VİLLA PROJESİ TAMİR BAKIM VE ONARIM İŞİ', 'GÖLBAŞI/ANKARA' ]

]

const projects_en = [
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'HOBBY GARDEN CONSTRUCTION JOB', 'AYAŞ/ANKARA' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'WINTER GARDEN APPLICATION PROJECT', 'MUSTAFA KEMAL MAHALLESİ / ANKARA' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'HOBBY GARDEN LANDSCAPING WORKS', 'ORTABEREKET/ANKARA' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'TINY HOUSE CONSTRUCTION WORKS', 'GÖYNÜK/BOLU' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'TINY HOUSE CONSTRUCTION AND RENOVATION WORKS', 'SAPANCA/SAKARYA' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'TINY HOUSE CONSTRUCTION RENOVATION WORKS', 'SAPANCA/SAKARYA' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'VILLA PROJECT CONSTRUCTION AND DESIGN WORKS', 'ALTINTERAZİ SİTESİ ÇAYYOLU/ANKARA' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'VILLA PROJECT CONSTRUCTION AND INTERIOR DESIGN WORKS', 'ANGORA EVLERİ  ÇANKAYA/ANKARA' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'VILLA PROJECT CONSTRUCTION AND INTERIOR DESIGN WORKS', 'MUTLUKENT MAH. ÇANKAYA/ANKARA' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'VILLA PROJECT CONSTRUCTION AND INTERIOR DESIGN WORKS', 'BAŞBEREKET/ANKARA' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', '7 FLOOR APARTMENT CONSTRUCTION AND INTERIOR DECORATION/DESIGN WORKS', 'MERKEZ/SİNOP' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'APARTMENT FLAT RENOVATION WORKS', 'MERKEZ/SİNOP' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'APARTMENT FLAT INTERIOR DECORATION WORKS', 'MERKEZ/SİNOP' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'APARTMENT FLAT RESTORATION INTERIOR ARCHITECTURAL DESIGN WORKS', 'MERKEZ/SİNOP' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'VILLA PROJECT CONSTRUCTION', 'ZEKERİYAKÖY/SARIYER/İSTANBUL' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'HOUSING PROJECT CONSTRUCTION', 'ASPAT AKYARLAR/BODRUM' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'VILLA PROJECT RENOVATION AND RENOVATION WORKS', 'ORTAKENT/BODRUM' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'HOUSING PROJECT RESTORATION WORKS', 'KONUTKENT MAH./ÇANKAYA/ANKARA' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'TOURISM OFFICE RENOVATION PROJECT', 'AKYARLAR/BODRUM' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'VILLA PROJECT CONSTRUCTION AND LANDSCAPE WORKS', 'İNCEBURUN/SİNOP' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'OFFICE DECORATION WORKS', 'YENİBATI/BATIKENT/ANKARA' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'PLASTIC SURGERY OFFICE DESIGN WORKS', 'MAİDAN PLAZA/ANKARA' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'SUMMER PROJECT RENOVATION WORKS', 'ANTHAVEN/BODRUM' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'APARTMENT FLAT INTERIOR DESIGN AND FURNITURE WORKS', 'RÖNESANS NEVA KONAKLARI/ANKARA' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'RESTORATION PROJECT', 'RÖNESANS NEVA KONAKLARI /ANKARA' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'DENTIST CLINIC RENOVATION AND REPAIR WORKS', 'İSTANBUL / BEYKOZ' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'APARTMENT FLAT RENOVATION AND REPAIR WORKS', 'KONUTKENT 2 /  ANKARA' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'HAIRDRESSER SALON RENOVATION AND MAINTENANCE REPAIR', 'KONUTKENT 2 / ANKARA' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'SHOPPING MALL CONSTRUCTION WORKS', 'OSTİM / ANKARA' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'MULTI-STOREY CAR PARK CONSTRUCTION WORKS', 'OSTİM/ANKARA' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'CAR PARK REPAIR-RENOVATION WORKS', 'MESA KORU / ANKARA' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'VILLA PROJECT RESTORATION RENOVATION', 'MESA KORU / ANKARA' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'SALES OFFICE INTERIOR DECORATION DESIGN', 'ORTAKENT/BODRUM /MUĞLA' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'LAW OFFICE OFFICE RENOVATION WORKS', 'SARIYER/İSTANBUL' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'MANSION CONSTRUCTION AND LANDSCAPE WORKS', 'İNCEK/ANKARA' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'APARTMENT FLAT RENOVATION AND FURNITURE DESIGN WORKS', 'KADIKÖY/İSTANBUL' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'RESIDENCE PROJECT INTERIOR DESIGN AND FURNITURE WORKS', 'ARNAVUTKÖY/İSTANBUL' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'GÜNERİ VILLAS REPAIR AND STRENGTHENING WORKS', 'MERKEZ/SİNOP' ],
    [ 'CONSTRUCTION JOB PRIVATE SECTOR', 'PREFABRICATED BUILDING CONSTRUCTION WORKS', 'GÖCEK/FETHİYE' ],
    [ 'SERVICE JOB PRIVATE SECTOR', 'VILLA PROJECT REPAIR MAINTENANCE AND REPAIR WORKS', 'GÖLBAŞI/ANKARA' ]
]

export { projects_tr, projects_en }